@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');

.underConstruction {
  background-image: url('./assets/images/banner.jpg');
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;

  h3 {
    font-size: 50px;
    // color: #E0CA3C;
    background-color: #e0ca3ce8;
    font-family: "Staatliches", sans-serif;
    font-weight: 400;
    font-style: normal;
    // background-color: #DD6031;
    color: #DD6031;
    padding-inline: 25px;
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.5) 10px 10px 10px;
  }

.constructionLogo {
  width: 30vw;
}
@media (max-width: 850px) {
  .constructionLogo {
    width: 90vw;
  }
  h3 {
    font-size: 40px;
    text-align: center;
    width: 75vw;
    padding-block: 10px;

  }
}

}


